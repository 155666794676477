const paths = {
  home: {
    root: () => '/',
  },
  band: {
    root: () => '/bandi/',
  },
  setlist: {
    root: () => '/settilista/',
  },
  gallery: {
    root: () => '/galleria/',
  },
  contact: {
    root: () => '/ota-yhteytta/',
  },
  privacyPolicy: {
    root: () => '/tietosuojaseloste/',
  },
};

export default paths;
