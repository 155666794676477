import { useStaticQuery, graphql } from 'gatsby';
import useSiteMetadata from './useSiteMetadata';

const useSitePageMetadata = () => {
  const { navItems } = useSiteMetadata();
  const pageMetadata = useStaticQuery(graphql`
    query {
      frontpage: sanityFrontpage(_id: {eq: "singleton-frontpage"}) {
        pageName
        title
      }
      band: sanityBand(_id: {eq: "singleton-band"}) {
        pageName
        title
      }
      setlist: sanitySetlist(_id: {eq: "singleton-setlist"}) {
        pageName
        title
      }
      galleryPage: sanityGalleryPage(_id: {eq: "singleton-galleryPage"}) {
        pageName
        title
      }
      contact: sanityContact(_id: {eq: "singleton-contact"}) {
        pageName
        title
      }
    }
  `);

  const findTitleFor = (pageName) => Object.values(pageMetadata)
    .find((page) => page.pageName === pageName)
    .title;

  return {
    pageMetadata,
    navItems: navItems.map((navItem) => ({ ...navItem, label: findTitleFor(navItem.name) })),
  };
};

export default useSitePageMetadata;
