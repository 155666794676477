import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000000;
    --white: #FFFFFF;
    --color-primary-darkest: #240f20;
    --color-primary-darker: #33162e;
    --color-primary: #421d3b;
    --color-primary-alpha7: rgba(66, 29, 59, 0.7);
    --color-primary-alpha8: rgba(66, 29, 59, 0.8);
    --color-primary-light: #4f2347;
    --color-secondary-darker: #d1a900;
    --color-secondary: #f5c601;
    --color-bg: ${({ bgColor }) => bgColor};
    --default-font-size: 1rem;
    --spacer: 1rem;
  }

  html, body {
    font-size: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--color-bg);
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    min-height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  img {
    max-width: 100%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

export default GlobalStyles;
