import React from 'react';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Header from './Header';
import Footer from './Footer';
import useSiteSettings from '../hooks/useSiteSettings';

const Layout = ({ children }) => {
  const { bgColor } = useSiteSettings();
  return (
    <>
      <GlobalStyles bgColor={bgColor.hex} />
      <Typography />
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
