import { useStaticQuery, graphql } from 'gatsby';

const useSiteSettings = () => {
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings: sanitySiteSettings(_id: {eq: "singleton-siteSettings"}) {
        description
        instagramUrl
        bgColor {
          hex
        }
      }
    }
  `);
  return siteSettings;
};

export default useSiteSettings;
