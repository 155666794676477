import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import paths from '../../paths';
import useSiteMetadata from '../hooks/useSiteMetadata';

const FooterStyles = styled.footer`
  margin-top: auto;
  text-align: center;
  margin: var(--spacer);
  color: var(--black);
`;

const ParagraphStyles = styled.p`
  margin: 0.5rem;
`;

const Footer = () => {
  const { title } = useSiteMetadata();
  return (
    <FooterStyles>
      <ParagraphStyles>
        {title}
        {' '}
        &copy;
        {' '}
        {new Date().getFullYear()}
      </ParagraphStyles>
      <ParagraphStyles>
        <Link to={paths.privacyPolicy.root()}>Tietosuojaseloste</Link>
      </ParagraphStyles>
    </FooterStyles>
  );
};

export default Footer;
