import React from 'react';
import logo from '../images/Instagram_Glyph_Gradient_RGB.svg';

const HeaderInstagramLogo = () => (
  <img
    src={logo}
    alt="Musabuffa instagram"
    width="40"
    height="40"
    style={{ minWidth: '40px' }}
  />
);

export default HeaderInstagramLogo;
