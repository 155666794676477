exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bandi-jsx": () => import("./../../../src/pages/bandi.jsx" /* webpackChunkName: "component---src-pages-bandi-jsx" */),
  "component---src-pages-galleria-jsx": () => import("./../../../src/pages/galleria.jsx" /* webpackChunkName: "component---src-pages-galleria-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ota-yhteytta-jsx": () => import("./../../../src/pages/ota-yhteytta.jsx" /* webpackChunkName: "component---src-pages-ota-yhteytta-jsx" */),
  "component---src-pages-settilista-jsx": () => import("./../../../src/pages/settilista.jsx" /* webpackChunkName: "component---src-pages-settilista-jsx" */),
  "component---src-pages-tietosuojaseloste-jsx": () => import("./../../../src/pages/tietosuojaseloste.jsx" /* webpackChunkName: "component---src-pages-tietosuojaseloste-jsx" */)
}

