import React from 'react';
import styled from 'styled-components';
import logo from '../images/musabuffa-logo-small.png';

const ImgStyles = styled.img`
  min-width: 60px;
`;

const HeaderLogo = () => (
  <ImgStyles
    src={logo}
    alt="Musabuffa"
    width="60"
    height="60"
  />
);

export default HeaderLogo;
