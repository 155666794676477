import React, { createRef, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import paths from '../../paths';
import HeaderLogo from './HeaderLogo';
import HeaderInstagramLogo from './HeaderInstagramLogo';
import useHeaderAndNav from '../hooks/useHeaderAndNav';
import useSiteSettings from '../hooks/useSiteSettings';
import useSitePageMetadata from '../hooks/useSitePageMetadata';

const headerHeight = 80;

const HeaderStyles = styled.header`
  --z-index-header: 10;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 10px;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  position: fixed;
  top: ${({ isHeaderHidden, height }) => (isHeaderHidden ? `-${height}px` : 0)};
  left: 0;
  z-index: var(--z-index-header);
  background-color: var(--color-primary);
  transition: top .3s ease;

  @media (prefers-reduced-motion) {
    top: 0;
  }

  img {
    display: flex;
  }

  .ig-nav-container {
    display: flex;
    align-items: center;

    > a {
      display: flex;
      padding: 0 10px;
    }
    > div {
      padding: 0 10px;
    }
  }
`;

const NavigationContainerStyles = styled.div`
  --nav-duration: 0.25s;
  --nav-timing: cubic-bezier(.24,1.22,.5,1.04);
  --z-index-below: -1;

  nav {
    display: none;
    position: fixed;
    top: ${({ top }) => `${top}px`};
    left: 0;
    align-items: center;
    width: 100%;
    height: ${({ top }) => `calc(100% - ${top}px)`};
    padding: 0 var(--spacer);
    transform: translateY(-100%);
    transition: var(--nav-duration);
    z-index: var(--z-index-below);

    @media (orientation: landscape) and (max-height: 640px) {
      padding: var(--spacer);
      align-items: flex-start;
    }

    @media (orientation: landscape) and (max-height: 360px) {
      padding: 0;
      align-items: flex-start;
    }
    
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-primary);
      transform-origin: 0 50%;
      z-index: var(--z-index-below);
    }

    ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      columns: 1;

      @media (orientation: landscape) and (max-height: 640px) {
        columns: 2;
      }   
    }

    li {
      transform: translateY(-100vh);
    }

    a {
      color: var(--black);
      text-decoration: none;
      line-height: 1;
      user-select: none;

      &:hover, &:focus, &:active {
        text-decoration: none;
        span {
          background: var(--color-secondary-darker);
        }
      }
    }

    .nav-link-active {
      span {
        text-decoration: underline;
        background: var(--color-secondary);
      }
    }

    span {
      display: block;
      font-family: 'Yeseva One',cursive;
      font-size: 8vmin;
      text-transform: uppercase;
      color: var(--color-primary);
      background: var(--color-secondary);
      padding: calc(var(--spacer) * 1.5) calc(var(--spacer) * 2);
      transition: all .5s;
      text-align: center;
      margin: 0 0 var(--spacer) 0;
    }
  }

  ${({ isNavOpen }) => isNavOpen
  && `
  nav {
    display: flex;
    transition-duration: 0s;
    transform: translateY(0);
    
    &:after {
      animation: navigation-background var(--nav-duration) var(--nav-timing) forwards;

      @media (prefers-reduced-motion) {
        animation: none;
      }
    }
    
    li {
      animation: link-appear var(--nav-duration) var(--nav-timing) forwards;

      @media (prefers-reduced-motion) {
        animation: none;
        transform: none;
      }

      &:nth-of-type(4) {
        animation-delay: calc(var(--nav-duration) * 0.25);
      }
      &:nth-of-type(3) {
        animation-delay: calc(var(--nav-duration) * 0.5);
      }
      &:nth-of-type(2) {
        animation-delay: calc(var(--nav-duration) * 0.75);
      }
      &:nth-of-type(1) {
        animation-delay: var(--nav-duration);
      }
    }

    @keyframes navigation-background {
      from { transform: translateY(-100%) }
      to { transform: translateY(0) }
    }

    @keyframes link-appear {
      from { transform: translateY(-100vh); }
      to { transform: translateY(0); }
    }
  }
  `}

  button {
    all: unset;
    display: flex;
    cursor: pointer;

    :focus {
      outline: auto;
    }

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      stroke-width: 6;
    }

    .icon-group {
      transform: translateX(0);
      transition: transform var(--nav-duration) var(--nav-timing);

      ${({ isNavOpen }) => isNavOpen
      && `
      transform: translateX(200%);
      `}
    }

    .icon--open {
      stroke: var(--color-secondary);
    }

    .icon--close {
      stroke: var(--color-secondary);
      transform: translateX(-200%);
    }
  }
`;

const Header = () => {
  const { navItems } = useSitePageMetadata();
  const { instagramUrl } = useSiteSettings();

  const {
    isNavOpen,
    toggleNavOpen,
    isHeaderHidden,
  } = useHeaderAndNav(headerHeight);

  const handleButtonClick = () => toggleNavOpen(!isNavOpen);
  const handleLinkClick = () => toggleNavOpen(false);

  const navRef = createRef();
  const navToggleRef = createRef();

  useEffect(() => {
    const nav = navRef?.current;
    const button = navToggleRef?.current;
    if (nav && button) {
      const closeByEsc = (event) => {
        if (event.code === 'Escape') {
          if (isNavOpen) toggleNavOpen(false);
          button.focus();
        }
      };

      nav.addEventListener('keyup', closeByEsc);
      return () => {
        nav.removeEventListener('keyup', closeByEsc);
      };
    }
    return null;
  }, [isNavOpen]);

  return (
    <HeaderStyles height={headerHeight} isHeaderHidden={isHeaderHidden}>
      <Link to={paths.home.root()}>
        <HeaderLogo />
      </Link>
      <div className="ig-nav-container">
        <a href={instagramUrl} target="_blank" rel="noreferrer">
          <HeaderInstagramLogo />
        </a>
        <NavigationContainerStyles isNavOpen={isNavOpen} top={headerHeight}>
          <button
            ref={navToggleRef}
            type="button"
            id="main-navigation-toggle"
            onClick={handleButtonClick}
            aria-label={isNavOpen ? 'Sulje navigaation' : 'Avaa navigaation'}
            aria-expanded={isNavOpen}
            aria-controls="overlay-nav"
          >
            <svg className="icon-container" viewBox="0 0 60 30">
              <g className="icon-group">
                <g className="icon--open">
                  <path d="M 6 0 L 54 0" />
                  <path d="M 6 15 L 54 15" />
                  <path d="M 6 30 L 54 30" />
                </g>
                <g className="icon--close">
                  <path d="M 15 0 L 45 30" />
                  <path d="M 15 30 L 45 0" />
                </g>
              </g>
            </svg>
          </button>
          <nav ref={navRef} id="overlay-nav">
            <ul>
              {navItems.map((item) => (
                <li key={item.name}>
                  <Link to={item.path} onClick={handleLinkClick} activeClassName="nav-link-active">
                    <span>{ item.label }</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </NavigationContainerStyles>
      </div>
    </HeaderStyles>
  );
};

export default Header;
