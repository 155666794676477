import { useState, useEffect } from 'react';

const useHeaderAndNav = (headerHeight) => {
  const [isNavOpen, setNavOpen] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);

  const toggleNavOpen = (state) => {
    if (state) {
      document.getElementsByTagName('main')[0].setAttribute('inert', state);
      document.getElementsByTagName('footer')[0].setAttribute('inert', state);
    } else {
      document.getElementsByTagName('main')[0].removeAttribute('inert');
      document.getElementsByTagName('footer')[0].removeAttribute('inert');
    }
    setNavOpen(state);
  };

  const toggleHideHeader = () => {
    if (isNavOpen) return;
    const { scrollY } = window;
    if (scrollY > prevScrollY && scrollY > headerHeight) {
      setIsHeaderHidden(true);
    } else {
      setIsHeaderHidden(false);
    }
    setPrevScrollY(scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleHideHeader, true);
    return () => {
      window.removeEventListener('scroll', toggleHideHeader, true);
    };
  });

  return { isNavOpen, toggleNavOpen, isHeaderHidden };
};

export default useHeaderAndNav;
